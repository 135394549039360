import { useRouter } from 'next/router'
import { Text, Box, Link } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoBagOutline } from 'react-icons/io5'

interface CartDrawerEmptyStateProps {
  onClose?: () => void
}

export const CartDrawerEmptyState = ({ onClose }: CartDrawerEmptyStateProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const router = useRouter()

  return (
    <Box
      p={'md'}
      textAlign={'center'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'column'}
      mt={{ md: '67px' }}
    >
      <IoBagOutline aria-hidden size="32px" color="primary" />

      <Text textStyle={'heading-desktop-300'} mt={4} mb={3} tabIndex={0}>
        {formatMessage({ id: 'cart.emptyState.title' })}
      </Text>

      <Text textStyle={'body-100'} tabIndex={0}>
        {formatMessage({ id: 'cart.emptyState.subtitle' })}
      </Text>

      <Link
        mt={6}
        textStyle={'ui-buttonGhost-100'}
        style={{
          textDecorationLine: 'underline',
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
        href="/"
        onClick={onClose}
      >
        {formatMessage({ id: 'action.startShopping' })}
      </Link>
    </Box>
  )
}

import { Box, Skeleton } from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { IoBagOutline } from 'react-icons/io5'
import { useCheckedLoggedIn } from './account/hooks/useCheckLoggedIn'
interface CartIconProps {
  cartQuantity?: number
}

export const CartIcon = (props: CartIconProps) => {
  const { formatMessage } = useFormat({ name: 'common' })
  const { isLoggedIn, isLoggedOut } = useCheckedLoggedIn()

  return (
    <Skeleton isLoaded={isLoggedOut ? true : isLoggedIn} borderRadius={6}>
      <Box display={'flex'} width={'40px'} alignItems="center" justifyContent="center">
        <IoBagOutline aria-hidden size="26px" color="#111111" />
        <Box
          title={formatMessage({ id: 'header.cart.title' })}
          display="flex"
          transform="translate(12px, -10px)"
          alignItems="center"
          justifyContent="center"
          color="white"
          fontSize="xs"
          fontWeight="bold"
          borderRadius="base"
          bg={props.cartQuantity ? 'red.500' : 'transparent'}
          padding="0 2px"
          minW="15px"
          w="fit-content"
          h="fit-content"
          position="absolute"
        >
          {props.cartQuantity ? props.cartQuantity : ''}
        </Box>
      </Box>
    </Skeleton>
  )
}

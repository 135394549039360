/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import { useAccountCT } from 'frontastic/contexts'
import { useRewards } from 'composable/components/rewards-club/hooks/useRewards'

export const useUpdateCustomerGroup = () => {
  const { account: user, getAllCustomerGroups, updateCustomerGroup, getCustomerById } = useAccountCT()
  const { summary } = useRewards()

  const updateCustomerGroupIfNeeded = useCallback(async () => {
    if (!user || !summary) return

    const ctCustomerGroup = user?.customerGroup?.name?.toLowerCase()
    const marigoldCustomerGroup = summary?.current_tier?.tier_name?.toLowerCase()
    const customerID = user.accountId
    const customerGroups = await getAllCustomerGroups()
    const customerGroupToUpdate = customerGroups.find((group) => group.name.toLowerCase() === marigoldCustomerGroup)

    if (marigoldCustomerGroup !== ctCustomerGroup) {
      customerGroupToUpdate && (await updateCustomerGroup(customerGroupToUpdate.id))
    } else {
      const updatedCustomer = await getCustomerById()
      const currentCustomerGroup = customerGroups.find((group) => group.id === updatedCustomer.customerGroup.id)
      const currentCustomerGroupName = currentCustomerGroup?.name?.toLowerCase()

      if (currentCustomerGroupName !== marigoldCustomerGroup) {
        customerGroupToUpdate && (await updateCustomerGroup(customerGroupToUpdate.id))
      }
    }
  }, [user, summary, getAllCustomerGroups, updateCustomerGroup])

  return { updateCustomerGroupIfNeeded }
}

import { useAccountCT, useAuthV2User } from 'frontastic/contexts'
import { useEffect, useState } from 'react'
import cookieCutter from 'cookie-cutter'
import { AUTH_ZERO_COOKIE_NAME } from 'frontastic/contexts/authV2UserContext/constants'

const CHECK_LOGGED_IN_INTERVAL = 5000

export const useCheckedLoggedIn = () => {
  const { loggedIn } = useAccountCT()
  const { authZeroAccessToken } = useAuthV2User()
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn)
  const [isLoggedOut, setIsLoggedOut] = useState(!authZeroAccessToken && !loggedIn)

  useEffect(() => {
    setIsLoggedIn(loggedIn)
    setIsLoggedOut(!authZeroAccessToken && !loggedIn)

    if (!loggedIn && authZeroAccessToken) {
      const timeout = setTimeout(() => {
        cookieCutter.set(AUTH_ZERO_COOKIE_NAME, '', { path: '/', expires: new Date(0) })

        setIsLoggedOut(true)
        setIsLoggedIn(false) // Ensure it never gets stuck
      }, CHECK_LOGGED_IN_INTERVAL) // Wait  before resolving to logged-out state

      return () => clearTimeout(timeout)
    }
  }, [loggedIn, authZeroAccessToken])

  return { isLoggedIn, isLoggedOut }
}

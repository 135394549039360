import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'
import { mutate } from 'swr'

export const validateCoupon = async (couponCode: string): Promise<any> => {
  const res = await fetchApiHub('/action/coupon/validateCoupon', { method: 'POST' }, { couponCode })

  await mutate('/action/coupon/validateCoupon', res)

  return res
}

export const burnCoupon = async (couponCode: string): Promise<any> => {
  const res = await fetchApiHub('/action/coupon/burnCoupon', { method: 'POST' }, { couponCode })

  await mutate('/action/coupon/burnCoupon', res)

  return res
}
